import * as React from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import Link from "@mui/material/Link";
import { useNavigate,useLocation } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import MoonparkLogo from "../../assets/images/Moonlight_Black.svg";
import { login } from "../../controllers/authController";
import { setIdToken, setUserDetails, setUserId } from "../../utils/auth";
import { ButtonsListComponent, TextInputField } from "../../components";
import ShowPasswordIcon from "../../assets/images/eye-icon.svg";
import HidePasswordIcon from "../../assets/images/eye-icon-hide.svg";
import { showToast } from "../../utils/helper-functions/helperFunction";
import useMessage from "../../hooks/customHooks/useMessage";
import { errorConst } from "../../utils/text-constants";

const Login = () => {
  const navigate = useNavigate();
  const { setMsg } = useMessage();
  const location = useLocation();
  const from = (location.state as { from: Location })?.from?.pathname || '/admin/dashboard';

  const [showPassword, setShowPassword] = React.useState(false);
  const [showEmailErrorMessage, setShowEmailErrorMessage] = React.useState("");
  const [showPasswordErrorMessage, setShowPasswordErrorMessage] = React.useState("");
  const [isTokenExist, setIsTokenExist] = React.useState<boolean>(true);
  const [isLoading, setIsLoading] = React.useState(false);

  const validationSchema = Yup.object({
    email: Yup.string().email("Please enter a valid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });
  const onSuccess = async (response: any) => {
    setIsLoading(false);
    setIdToken(response.token);
    setUserDetails(response);
    setUserId(response.userId);
    navigate(from);
    // navigate("/admin/dashboard");
  };
  const onError = (error: any) => {
    setIsLoading(false);
    if (error?.data?.errors?.code === 1000) {
      setShowEmailErrorMessage("Email is invalid");
    } else if (error?.data?.errors?.code === 1001 || error?.data?.errors?.code === 1002) {
      setShowPasswordErrorMessage("Incorrect password");
    } else {
      showToast(
        setMsg,
        error?.message || error?.data?.message || errorConst.UNKNOW_ERROR,
        "error"
      );
    }
  };

  const submitLogin = (values: any) => {
    setIsLoading(true);
    login(onSuccess, onError, { ...values, password: window.btoa(values.password) });
  };

  React.useEffect(() => {
    if (localStorage.getItem("token")) {
      setIsTokenExist(true);
      navigate("/admin/dashboard");
    } else {
      setIsTokenExist(false);
    }
  }, [navigate]);

  return (
    <>
      {!isTokenExist && (
        <Box
          overflow="auto"
          sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width="100%"
            flexDirection={"row"}
          >
            <div>
              <img src={MoonparkLogo} alt="Logo" />
              <Typography variant="h3" color="darkGrey.main" mt={8} mb={4}>
                Log In
              </Typography>
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  submitLogin(values);
                }}
                validateOnChange
              >
                {({ errors, touched, values, handleChange }) => (
                  <Form style={{ width: "400px" }}>
                    <TextInputField
                      label="Email"
                      id="email"
                      placeholder="Enter Email"
                      name="email"
                      value={values.email}
                      error={(touched.email && errors.email) || showEmailErrorMessage}
                      errorText={errors.email || showEmailErrorMessage}
                      onChange={(e: any) => {
                        handleChange(e);
                        setShowEmailErrorMessage("");
                        setShowPasswordErrorMessage("");
                      }}
                    />
                    <TextInputField
                      label="Password"
                      id="password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter Password"
                      name="password"
                      value={values.password}
                      error={
                        (touched.password && errors.password) || showPasswordErrorMessage
                      }
                      errorText={errors.password || showPasswordErrorMessage}
                      onChange={(e: any) => {
                        handleChange(e);
                        setShowPasswordErrorMessage("");
                      }}
                      endIcon={showPassword ? ShowPasswordIcon : HidePasswordIcon}
                      onIconClick={() => setShowPassword(!showPassword)}
                    />
                    <div style={{ margin: "28px 0 12px" }}>
                      <Link
                        variant="caption"
                        color="orange.main"
                        sx={{
                          "&:hover": {
                            color: "orange.main",
                          },
                        }}
                        onClick={() => navigate("/forgot-password")}
                      >
                        Forgot password?
                      </Link>
                    </div>
                    <ButtonsListComponent
                      isLoading={isLoading}
                      buttonsIndexForLoader={[0]}
                      buttonsArray={[
                        {
                          type: "submit",
                          label: "Log In",
                          inlineStyle: { width: "100%" },
                        },
                      ]}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Login;
