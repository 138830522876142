/**
 * backbutton for the required pages in the app bar
 */
export const showBackButton = [
  "class-details",
  "edit-class",
  "add-new-admin",
  "edit-admin",
  "edit-email-template",
  "view-guide",
  "edit-guide",
  "view-broadcast-message-details",
  "new-broadcast-message",
  "edit-explorer",
  "explorer-details",
  "create-coupon",
  "edit-coupon",
  "view-coupon-details",
  "change-password"
];

export const shouldShowBackButton = (pathname: string) => {
  // Regular expression for dynamic URLs like /admin/class-details/:classId
  // const dynamicClassDetailsPattern = /^\/admin\/class-details\/[A-Za-z0-9_-]+$/;
  const dynamicDetailPatterns = /^\/admin\/(class-details|view-guide)\/[A-Za-z0-9_-]+$/;
  // Check if the last segment matches static routes or if it's a dynamic class details route
  return showBackButton.includes(pathname.split("/").pop() as string) || 
  dynamicDetailPatterns.test(pathname);
};
