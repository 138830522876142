import * as React from "react";
import { Navigate,useLocation } from 'react-router-dom';

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }:PrivateRouteProps) => {
    const location = useLocation();
    if (!localStorage.getItem("token")) {
      // Redirect to login and store the current location
      return <Navigate to="/login" state={{ from: location }} />;
    }
    return <>{children}</>;
};

export default PrivateRoute;
