import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes,Navigate} from "react-router-dom";
import { ThemeProvider } from "@emotion/react";

import AdminLayout from "./layout/admin-layout";
import DashboardPage from "./pages/admin/dashboard.page";
import ClassesPage from "./pages/admin/classes/ClassesListingPage";
import Login from "./pages/auth/login";
import CustomTheme from "./themes/theme";
import ForgotPassword from "./pages/auth/forgot-password";
import ResetPassword from "./pages/auth/reset-password";
import AdminsListingPage from "./pages/admin/admin-settings/AdminsListingPage";
import AddNewAdminPage from "./pages/admin/admin-settings/AddNewAdminPage";
import ChangePassword from "./pages/admin/admin-settings/ChangePassword";
import CustomMessageBar from "./components/customMessageBar";
import EditAdminPage from "./pages/admin/admin-settings/EditAdminPage";
import LinkExpired from "./pages/auth/linkExpired";
import ViewClassDetailsPage from "./pages/admin/classes/ViewClassDetailsPage";
import EditClassDetailsPage from "./pages/admin/classes/EditClassDetailsPage";
import PromotedClassPage from "./pages/admin/promoted-class/PromotedClassPage";
import GuideListingPage from "./pages/admin/guides/GuideListingPage";
import EmailTemplateListingPage from "./pages/admin/email-templates/EmailTemplateListingPage";
import EditEmailTemplatesPage from "./pages/admin/email-templates/EditEmailTemplatesPage";
import ExplorersListingPage from "./pages/admin/explorers/ExplorersListingPage";
import ListingBroadCastMessages from "./pages/admin/broadcast-messages/ListingBroadCastMessages";
import ViewGuidePage from "./pages/admin/guides/ViewGuidePage";
import EditGuidePage from "./pages/admin/guides/EditGuidePage";
import ViewBroadcastMessage from "./pages/admin/broadcast-messages/ViewBroadcastMessage";
import ViewExplorerDetailsPage from "./pages/admin/explorers/ViewExplorerDetailsPage";
import EditExplorerPage from "./pages/admin/explorers/EditExplorerPage";
import AddBroadcastMessage from "./pages/admin/broadcast-messages/AddBroadcastMessage";
import CouponsListingPage from "./pages/admin/coupons/listing/CouponsListingPage";
import CreateCouponPage from "./pages/admin/coupons/CreateCouponPage";
import EditCouponPage from "./pages/admin/coupons/EditCouponPage";
import ViewCouponDetails from "./pages/admin/coupons/ViewCouponDetails";
import { CustomMessageProvider } from "./components/customMessageBar/CustomMessageBar";
import "./assets/scss/moonlight.scss";
import PrivateRoute from "./utils/PrivateRoute"
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <CustomMessageProvider>
    <ThemeProvider theme={CustomTheme}>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Login />} />
            <Route path="login" element={<Login />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="link-expired" element={<LinkExpired />} />
            <Route path="admin/*" element={<PrivateRoute>
                <AdminLayout />
              </PrivateRoute>}>
              <Route path="dashboard" element={<DashboardPage />} />
              <Route path="classes" element={<ClassesPage />} />
              <Route path="class-details/:classId" element={<ViewClassDetailsPage />} />
              <Route path="edit-class" element={<EditClassDetailsPage />} />
              <Route path="explorers-listing" element={<ExplorersListingPage />} />
              <Route path="explorer-details" element={<ViewExplorerDetailsPage />} />
              <Route path="edit-explorer" element={<EditExplorerPage />} />
              <Route path="guides-listing" element={<GuideListingPage />} />
              <Route path="view-guide/:userId" element={<ViewGuidePage />} />
              <Route path="edit-guide" element={<EditGuidePage />} />
              <Route
                path="broadcast-message-listing"
                element={<ListingBroadCastMessages />}
              />
              <Route
                path="view-broadcast-message-details"
                element={<ViewBroadcastMessage />}
              />
              <Route path={"new-broadcast-message"} element={<AddBroadcastMessage />} />
              <Route path="email-templates" element={<EmailTemplateListingPage />} />
              <Route path="edit-email-template" element={<EditEmailTemplatesPage />} />
              <Route path="promoted-classes" element={<PromotedClassPage />} />
              <Route path="coupons-listing" element={<CouponsListingPage />} />
              <Route path="create-coupon" element={<CreateCouponPage />} />
              <Route path="edit-coupon" element={<EditCouponPage />} />
              <Route path="view-coupon-details" element={<ViewCouponDetails />} />
              <Route path="admins-listing" element={<AdminsListingPage />} />
              <Route path="add-new-admin" element={<AddNewAdminPage />} />
              <Route path="edit-admin" element={<EditAdminPage />} />
              <Route path="change-password" element={<ChangePassword />} />
            </Route>
          </Route>
          <Route path="*" element={<Navigate to="/login" />} /> {/* Catch-all route */}
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
    <CustomMessageBar />
  </CustomMessageProvider>
);
