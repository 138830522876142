import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import { AppBar, Typography, Tooltip } from "@mui/material";
import theme from "../../themes/theme";
import { IMAGES } from "../../assets/images";
import {shouldShowBackButton } from "../../utils/text-constants/appBarContent";
import { useNavigate } from "react-router-dom";

const NavBar = (props: any): JSX.Element => {
  const { selectedPage, drawerWidth } = props;
  const navigate = useNavigate();
  const classDetailsPattern = /^\/admin\/class-details\/[A-Za-z0-9_-]+$/;
  const guideDetailsPattern = /^\/admin\/view-guide\/[A-Za-z0-9_-]+$/;
  // Determine if we are on a class details page or guide details page
  const isClassDetail = classDetailsPattern.test(window.location.pathname);
  const isGuideDetail = guideDetailsPattern.test(window.location.pathname);
  const handleBackNavigation = () => {
    if (isClassDetail) {
      navigate('/admin/classes'); // Navigate to classes list if on class details page
    } else if (isGuideDetail) {
      navigate('/admin/guides-listing'); // Navigate to guides list if on guide details page
    } else {
      navigate(-1); // Default back navigation for other routes
    }
  };
  let pageTitle = isClassDetail ? "Experiences" : isGuideDetail ? "Guide" : selectedPage;
    if(window.location?.pathname === '/admin/guides-listing'){
      pageTitle = 'Guide'
    }else if(window.location?.pathname === '/admin/classes'){
      pageTitle = 'Experiences'
    }
  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: theme.palette.yellow.light,
        width: `calc(100% - ${drawerWidth}px)`,
        ml: `${drawerWidth}px`,
        p: 1,
      }}
      elevation={0}
    >
      <Toolbar sx={{ gap: 3 }}>
      {shouldShowBackButton(window.location.pathname) && (
        
        <Tooltip title={"Go Back"}>
          <img
          style={{ cursor: "pointer" }}
          onClick={handleBackNavigation}
          src={IMAGES.backArrowIcon}
          alt="Go Back"
        />
        </Tooltip>
      )}
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{
            flexGrow: 1,
            color: "#353535",
            fontWeight: 600,
            fontSize: "30px",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          {pageTitle}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export { NavBar };
